@import '../../variables.scss';

.page-title-container {
  .details-container {
    display: none;
  }

  &.active {
    height: auto;
    flex-direction: column;

    & > div {
      width: 100%;
    }

    .org-tree-container {
      padding: 10px 0;
    }

    .details-container {
      display: block;
      .org-info-container {
        display: flex;
        flex-direction: column;

        .box-conatiner {
          position: relative;
          padding-left: 11px;

          .title-container {
            display: flex;
            padding-top: $padding-xs;
            justify-content: center;
          }
          .org-data {
            display: flex;

            .box {
              min-width: 350px;
              padding: 2px 0;
            }

            .label {
              font-weight: $bold-weight;
              padding-right: $padding-xs;
            }
          }
        }
      }
    }

    .tabs-container {
      justify-content: flex-end;
      height: 40px;
      margin-top: -20px;
      z-index: 999;
    }
  }
}
