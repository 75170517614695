@import '../variables.scss';

$icon-size: 24px;
$space-between-icon-and-label: 8px;

.main-nav {
  height: $navigation-height;
  background-color: $navigation-bg;
  color: $light-text-color;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 0 $content-padding;

  .nav-icon {
    font-size: $icon-size;
    margin-right: $space-between-icon-and-label;
  }
  a,
  .selected-language {
    color: $light-text-color;
    font-size: $font-size-lg;
    &:hover {
      color: $light-text-color;
    }
  }

  .logo-container {
    padding-right: 20px;
    display: flex;
    height: 100%;
    align-items: center;

    .toggle-menu {
      display: none;
    }

    .brand-logo {
      display: flex;

      .logo-image {
        max-width: 40px;
        max-height: 40px;
      }

      .logo-title {
        font-size: 26px;
        padding-left: $space-between-icon-and-label;
      }
    }
  }

  .navbar {
    padding: 0 20px;
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;

    .main-nav-link {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 20px;

      &.active {
        background-color: $active-navigation-bg;
      }
    }
  }

  .navbar-links {
    padding-left: 20px;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;

    .dropdown-menu-header {
      list-style: none;
      text-transform: uppercase;
      padding: 0 15px;
      position: relative;
      display: flex;
      height: 100%;
      align-items: center;
      cursor: pointer;

      .arrow-icon {
        margin-left: $space-between-icon-and-label;
        transform: rotate(0deg);
        transition: all 0.25s ease-in;

        &.active {
          transform: rotate(180deg);
          transition: all 0.25s ease-out;
        }
      }

      .user-icon {
        font-size: $icon-size;
        background: rgb(76, 87, 123);
        padding: 10px;
        border-radius: 50%;
      }

      .dropdown-menu-content-wrapper {
        min-width: 90px;
        box-shadow: 0 2px 2px 0 lightgrey, 0 3px 1px -2px lightgrey, 0 1px 5px 0 lightgrey;
        position: absolute;
        top: $navigation-height;
        right: 0px;
        background: white;
        font-size: 15px;
        padding: 0;
        transform: scaleY(0);
        transition: transform 0.3s ease-out;
        transform-origin: top;
        overflow: hidden;
        height: 0;
        margin: 0;
        z-index: 9999;

        &.active {
          height: auto;
          transform: scaleY(1);
        }

        li {
          
          list-style: none;
          text-align: center;
          &:hover {
            background-color: #f5f5f5;
            cursor: pointer;
          }
          .dropdown-item {
            padding: 10px 20px;
            white-space: nowrap;
            color: black;
          }
        }
      }
    }
  }
}

@media (max-width: 1180px) and (min-width: 861px) {
  .main-nav {
    .nav-icon {
      margin: 0 5px;
    }
    .navbar {
      .main-nav-link {
        .label {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 860px) {
  .main-nav {
    &.closed {
      height: $navigation-height;
    }
    height: auto;
    .logo-container {
      width: 100%;
      padding: 10px 0px;
      justify-content: space-between;

      .toggle-menu {
        display: block;
        font-size: $icon-size;
      }
    }
    .navbar {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 55px;
      .main-nav-link {
        padding: 10px;

        &.active {
          background-color: transparent;
          border-bottom: 3px solid $active-navigation-bg;
        }
      }
    }
    .navbar-links {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 55px;

      .dropdown-menu-header {
        padding: 10px;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;

        .dropdown-menu-content-wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          top: 0;
          left: 0;
          box-shadow: none;
          background-color: transparent;

          li {
            text-align: left;
            &:hover {
              background-color: transparent;
              .dropdown-item {
                border-bottom: 3px solid $active-navigation-bg;
              }
            }
            .dropdown-item {
              color: white;
              padding: 10px;
            }
          }
        }
      }
    }

    &.closed {
      .navbar,
      .navbar-links {
        display: none;
      }
    }
  }
}
