//----------- COLORS -------------
// $primary-color: #394b71;
// $highlight-color: #18b5d8;
// $primary-color: #3B566E;
$highlight-color: #22B4de;
$primary-color: #47AABD;
$blue: #00A8CF;
$light-blue: #EEFCFF;
$white: #fff;
$black: rgba(0, 0, 0, 0.85);
$gray: #f0f0f0;
$light-gray: #fafafa;
$light-black: rgba(0, 0, 0, 0.85);
$red: #F44336;
$green: #26A69A;
$yellow: #ffc828;


$warning-color: $yellow;
$error-color: $red;
$success-color: $green;

$main-bg: $white;
$dark-text-color: $black;
$light-text-color: $white;
$link-text-color: $primary-color;

$form-item-bg: #efedf5;
//NAVIGATION
$navigation-bg: #394b71;
$active-navigation-bg: $primary-color;

$second-level-nav-separator-color: $gray;

//LOGIN
$login-form-bg: $white;
$login-button: $primary-color;
$login-link: $primary-color;

//BUTTON
$button-bg: $primary-color;
$button-border-color: $primary-color;
$button-text-color: $white;

$org-details-box-bg: $light-gray;
$org-details-box-border: $gray;

//TABLE
$table-header-color: $light-black;
$table-background: $light-gray;
$table-border: $gray;

$icon-color: $primary-color;

//---------- PADDINGS --------------
$alert-padding: 4px;
$content-padding: 40px;

$padding-lg: 24px;
$padding-md: 16px;
$padding-sm: 12px;
$padding-xs: 8px;
$padding-xss: 4px;

$navigation-height: 65px;
$second-level-navigation-height: 65px;

//---------- FONTS --------------
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
$open-sans: 'Open Sans', sans-serif;

$font-size-base: 16px;
$font-size-lg: $font-size-base + 4px;
$font-size-sm: 12px;
$font-size-title: 22px;

$light-weight: 300;
$normal-weight: 400;
$semibold-weight: 600;
$bold-weight: 700;
