.user-form {
    .ant-form-item-label > label {
        font-weight: 600;
    }
}

.filters {
    padding-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .filter-by {
        font-weight: bold;
        padding: 0 10px;
    }
    .filter-input {
        min-width: 320px;
        padding-right: 10px;
    }
}