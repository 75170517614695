@import '../../../variables.scss';


%view-mode-shared {
  color: rgba(0, 0, 0, 0.85) !important;
  cursor: auto !important;
}

.custom-input {
  &.view-mode {
    &.with-background {
      background-color: $form-item-bg !important;
    }

    &:disabled {
      @extend %view-mode-shared;
    }
  }
}

.custom-select {
  min-width: 40px;
  width: 100%;
  &.view-mode {
    &.with-background {
      background-color: $form-item-bg !important;
    }
  }
  &.view-mode * {
    @extend %view-mode-shared;
  }
}

.custom-textarea {
  &.view-mode {
    &.with-background {
      background-color: $form-item-bg !important;
    }
    @extend %view-mode-shared;
  }
}

.custom-checkbox {
  &.view-mode * {
    @extend %view-mode-shared;
  }
  &.ant-switch-checked {
    background-color: $primary-color !important;
  }
}



.custom-date-picker {
  width: 100%;

  cursor: auto !important;
  &.view-mode * {
    @extend %view-mode-shared;
  }

  &.view-mode {
    .ant-picker-input {
      .ant-picker-suffix {
        display: none;
      }
    }
  }
}

.custom-number-input {
  width: 100% !important;

  &.view-mode.with-background {
    background-color: $form-item-bg !important;
  }
  
  &.view-mode.ant-input-number-disabled {
    background-color: white;
    @extend %view-mode-shared;
  }
  &.view-mode * {
    @extend %view-mode-shared;
  }
}

.custom-tooltip {
  .ant-tooltip-inner {
    color: black !important;
    padding: 15px !important;
  }
}

.alert {
  &.error {
    padding: 4px 10px !important;
  }
}


.ant-table {
  font-size: $font-size-base !important;
}

.custom-tag {
  font-size: $font-size-base !important;
  padding: 3px 7px !important;

  &.blue {
    color: $blue;
    background-color: $light-blue;
    border-color: $blue;
  }

  // &.purple {
  //   color: #531dab;
  //   background-color: #f9f0ff;
  //   border-color: #d3adf7;
  // }
}

.ant-form {
  .ant-form-item-label > label {
    font-size: 16px !important;
  }
}

.ant-form-horizontal .ant-form-item-label {
  display: flex !important;
  align-items: center !important;;
}

.ant-picker.ant-picker-borderless.view-mode {
  &.with-background {
    background-color: $form-item-bg !important;
  }
}

.custom-message {
  font-size: 18px;
  &.success {
    .anticon {
      color: $success-color;
      font-size: 22px;
    }
  }

  &.error {
    .anticon {
      color: $error-color;
      font-size: 22px;
    }
  }

  &.warning {
    .anticon {
      color: $warning-color;
      font-size: 22px;
    }
  }
}