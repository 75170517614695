@import '../../../variables.scss';

.details-modal {
  .ant-modal-header {
    height: 55px;
    padding: 0 24px;
  }

  .ant-modal-title {
    height: 100%;

    .modal-tabs {
      width: 100%;
      padding: 0;
      height: 55px;
      .tabs-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 100%;
      }
    }
  }

  .modal-footer {
    border-radius: 0 0 2px 2px;
  }

  .alias-cell {
    width: 40%;
  }

  .roles-cell {
    width: 40%;
  }


  .actions-cell {
    width: 20%;
  }
}

.capabilities-modal {
  .label-cell {
    width: 25%;
  }

  .description-cell {
    width: 25%;
  }

  .valid-from-cell {
    width: 15%;
  }

  .valid-to-cell {
    width: 15%;
  }

  .notify-cell {
    width: 10%;
  }
  .actions-cell {
    width: 10%;
  }
}

.member-actions-modal-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 14px;

  .step-actions > button {
    margin-left: 10px;
  }
}


.capabilities-modal {
  .ant-form-item-explain.ant-form-item-explain-error {
    white-space: nowrap;
  }
}