@import '../../../variables.scss';

.page-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $second-level-nav-separator-color;
  height: $second-level-navigation-height;
  padding: 0 $content-padding;

  .tabs-container {
    display: flex;
    height: 100%;
    margin-bottom: -1px;

    .tab {
      font-size: $font-size-lg;
      color: $dark-text-color;
      min-width: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 3px;

      &:hover {
        cursor: pointer;
      }

      &.active {
        border-bottom: 3px solid $link-text-color;
        padding-bottom: 0;
      }
    }
  }
}

.org-tree-container {
  display: flex;
  align-items: center;
  .org-tree-select {
    font-size: $font-size-title;
  }

  .ant-select-arrow {
    color: black;
  }
}

.page-content-wrapper {
  padding: 24px $content-padding;
  width: 100%;
//  height: calc(100vh - #{$navigation-height} - #{$second-level-navigation-height});
  display: flex;
  flex-direction: column;
  background-color: white;
}

