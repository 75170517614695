@import './variables.scss';

.root-container {
  height: 100%;
  font-size: $font-size-base;

  .main-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - #{$navigation-height});
    overflow: auto;

    .content {
      flex: 1 0 auto;
    }
    footer {
      flex-shrink: 0;
      padding: 5px $content-padding;
      border-top: 1px solid $gray;
    
      .footer-text-container {
        padding-top: 8px;
        padding-bottom: 8px;
        text-align: center;
        a {
          color: #16679d;
          font-size: 14px;
          font-weight: bold;
        }
      }


      .footer-logo-container {
        display: flex;
        justify-content: center;
        padding: 0 20px;
        align-items: center;

        .footer-logo-merged-elements {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .slogen-image {
            height: 30px;
            width: auto;
            margin: 0 0 5px 5px;
          }
        }

        .footer-logo-image {
          height: 45px;
          margin: 5px;
        }

        .footer-logo-image-big {
          height: 65px;
          margin: 5px;
        }
      }
    }
  }

  .table-container {
    .new-item-container {
      display: flex;
      padding-bottom: $padding-xs;
    }

    table {
      th {
        font-weight: $bold-weight !important;
      }
    }
  }
}

.table-collapse {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  font-size: $font-size-base;
  width: fit-content;

  .table-row {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #f0f0f0;

    &.table-row-header {
      background: $table-background;
    }

    &.inactive {
      pointer-events: none;
      opacity: 0.4;
    }
  }

  .table-cell {
    box-sizing: border-box;
    flex-grow: 1;
    padding: 5px 10px;
    // overflow: hidden;
    list-style: none;
    &.column-header {
      padding: 16px;
      font-weight: $bold-weight;
      color: $table-header-color;
    }
  }

  .table-cell-content {
    display: flex;
    height: 100%;
    align-items: center;

    .ant-row {
      margin-top: 24px;
      width: 100%;
    }
  }
}

.two-column-flex-form {
  display: flex;
  flex-grow: 1;
  & > div {
    width: 50%;
    &:first-child {
      padding-right: 12px;
    }
    &:last-child {
      padding-left: 12px;
    }
  }
}

.ant-btn[disabled] {
  opacity: 0.4;
}
.custom-button {

  &.delete, &.reset, &.rejected, &.declined {
    background: $red !important;
    color: $light-text-color !important;
    border-color: #394b71 !important;

    &:hover {
      background: $red !important;
      color: $light-text-color !important;
      border-color: #394b71 !important;
    }

  }

  &.edit, &.create, &.save, &.approved {
    background: $primary-color !important;
    color: $light-text-color !important;
    border-color: #394b71 !important;

    &:hover {
      background: $primary-color !important;
      color: $light-text-color !important;
      border-color: #394b71 !important;
    }
  }

  &.approved, &.accepted {
    background: $green !important;
    color: $light-text-color !important;
    border-color: #394b71 !important;

    &:hover {
      background: $green !important;
      color: $light-text-color !important;
      border-color: #394b71 !important;
    }
  }

  &.info, &.cancel {
    background: $white !important;
    color: #394b71 !important;
    border-color: #394b71 !important;

    &:hover {
      background: $white !important;
      color: #394b71 !important;
      border-color: #394b71 !important;
    }
  }
}


.ant-modal {
  .ant-modal-header {
    background: $navigation-bg !important;
    .ant-modal-title {
      color: $light-text-color !important;
      font-weight: bold;
    }
  }
}


.ant-modal-close {
  color: $light-text-color !important;
}

.details-modal {
  .ant-modal-header {
    background: white !important;
  }
}

.error-container {
  margin-bottom: 32px;
  color: $error-color;

  &.visible {
    margin-bottom: 0;
  }
}

.warning {
  color: $warning-color !important;
}