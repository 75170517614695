@import '../../variables.scss';

$logo-title-font-size: 27px;
$login-form-min-width: 350px;
$spacing: 32px;

.login-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo-container {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;

    a {
      color: $dark-text-color;
      font-size: $logo-title-font-size;
      display: flex;
      align-items: center;

      span {
        font-size: 32px
      }
    }
  }

  .login-form {
    min-height: 465px;
    box-shadow: rgba(149, 157, 165, 0.20) 0px 8px 24px;
    padding: 30px 20px;
    background-color: $login-form-bg;
    min-width: $login-form-min-width;

    border: 1px solid $login-form-bg;
    border-radius: 10px;

    .login-title {
      font-size: 28px;
      text-align: center;
      margin: 0;
      padding-bottom: 4px;
    }

    .forgot-password-container {
      display: flex;
      justify-content: flex-end;

      a {
        color: $link-text-color;
      }
    }

    .login-button-container {
      padding-top: $spacing;
      display: flex;
      justify-content: center;

      button {
        background: $primary-color;
        border-color: $primary-color;
        width: 100%;
        border-radius: 10px;
        height: 45px;
        span {
          font-weight: 800;
        }
      }
    }
  }

  .login-footer-container {
    display: flex;
    justify-content: space-around;
    padding-top: 10px;

    span {
      color: $dark-text-color;
      font-size: 14px;

      &:hover {
        cursor: pointer;
      }

      &.active {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
