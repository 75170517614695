@import '../../variables.scss';

.event-type-icon {
  color: $icon-color;
  border-radius: 50%;
  box-shadow: 0px 0px 2px #888;
  padding: 9px 9px;
  width: 55px !important;
  height: 55px !important;

  &.mission {
    color: $red;
  }

  &.details-screen {
    padding: 12px 12px;
    width: 80px !important;
    height: 80px !important;
  }
}

.event-details-container {
  overflow: auto;
  .title-container {
    display: flex;
    align-items: center;
    .title {
      font-size: $font-size-title;
    }
  }



  .actions-container {
    display: flex;
    justify-content: space-between;

    &.save-button {
      justify-content: flex-end;
    }

    .event-state-actions {
      display: flex;
      .event-state-tag {
        display: flex;
        align-items: center;
        font-weight: 700;
      }
    }
    .action-buttons-container {
      button:first-child {
        margin-right: 8px;
      }
    }
  }

  .event-form-container {
    padding-top: 28px;
    display: flex;

    .form-description-container {
      .ant-form-item {
        height: calc(100% - 33px);
      }

      .ant-form-item-control-input,
      .ant-form-item-control-input-content,
      textarea {
        height: 100%;
      }
    }

    .event-icon-container {
      padding-right: 32px;
      img {
        width: 55px;
      }
    }
    .ant-form-item-label > label {
      font-weight: 600;
    }

    .notification-container {
      display: flex;
      justify-content: space-between;
    }
  }

  .invites-container {
    .label {
      font-weight: 600;
    }
    .invite-item-container {
      width: 100%;
      display: inline-flex;

      .two-column-flex-form {
        width: calc(100% - 19px);
      }

      .two-column-flex-form > div:last-child {
        margin-left: 14px;
      }

      .minus-button {
        font-size: 19px;
      }
    }
  }
}

.table-collapse {
  .capability-cell {
    width: 40%;
  }
  .my-offers-cell {
    display: flex;
    justify-content: flex-end;
    overflow: visible;
    width: 20%;
  }
  .accepted-cell {
    display: flex;
    justify-content: center;
    width: 15%;
  }
  .rejected-cell {
    display: flex;
    justify-content: center;
    width: 15%;
  }

  .state-cell {
    display: flex;
    justify-content: center;
    width: 15%;

    // .anticon {
    //   font-size: 26px;
    // }

  }

  .all-offers-cell {
    display: flex;
    justify-content: center;
    width: 10%;
  }
}

.history-filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 8px;
  p {
    margin: 0;
    padding-right: 8px;
  }
}

.active-events-filter-container {
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;

  .filters {
    display: flex;

    .text-filter {
      min-width: 300px;
      padding-right: 20px;
    }
  }
}

.month-picker-container {
  display: flex;

  .custom-date-picker {
    margin: 0 5px;
  }
}

.ant-table-expanded-row {
  .ant-table {
    margin: -16px -16px 10px 65px;
  }
  .ant-table-thead {
    display: none;
  }
}

.capability-icon {
  &.show {
    visibility: visible;
  }
  &.error {
    color: $red;
  }
  visibility: hidden;
  padding-left: 8px;
  font-size: 18px;
}

.capability-offer-tooltip-container {
  p {
    margin: 0;
  }

  .title {
    padding-bottom: 8px;
  }

  .total {
    padding-top: 8px;
  }
  .list {
    margin: 0;
  }

  .empty {
    display: flex;
    justify-content: center;
    font-weight: 700;
  }

  .summary {
    padding-right: 8px;
    font-weight: 700;
  }
}

.accepted {
  font-size: 25px;
  color: $green !important;
}

.offered {
  font-size: 25px;
  color: $light-black !important;
}

.declined {
  font-size: 25px;
  color: $red !important;
}